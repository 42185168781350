<template>
	<div>
		<template>
			<v-toolbar
				flat
				color="primary"
				dark
			>
				<v-toolbar-title>{{exercise.name+
					(exercise.competition&&exercise.test&&exercise.training?
					" - Teszt-, verseny- és foglalkozásgyakorlat"
					:(exercise.competition&&exercise.test?" - Teszt- és versenygyakorlat"
					: (exercise.competition&&exercise.training?" - Verseny- és foglalkozásgyakorlat"
					:(exercise.test&&exercise.training? " - Teszt- és foglalkozásgyakorlat"
					:(exercise.test?" - Tesztgyakorlat"
					:(exercise.training?" - Foglalkozásgyakorlat"
					:" - Versenygyakorlat"))))))}}</v-toolbar-title>
			</v-toolbar>
			<v-sheet>
				<v-chip-group>
					<v-chip
						color="success"
						v-for="CAT in exercise.Exercise_Categories"
						:key="CAT.id"
						:close="$store.getters.userRole>=roles.nationalCoordinator"
						@click:close="dialogs.acceptDeleteExercise_Category.show=true, dialogs.acceptDeleteExercise_Category.exerciseCategoryId=CAT.id"
					>
						{{exerciseCategories.find(cat => cat.id==CAT.id).name}}
					</v-chip>
					<v-chip
						color="primary"
						v-if="$store.getters.userRole>=roles.nationalCoordinator"
						@click="dialogs.addExercise_Category.show=true"
					>
						Mozgásterület hozzáadása
						<v-icon right>fa-plus</v-icon>
					</v-chip>
				</v-chip-group>
				<v-chip-group>
					<span v-for="MU in exercise.Exercise_MeasurementUnits" :key="MU.MeasurementUnitId">
						<v-tooltip top v-for="aG in MU.ExerciseMU_Age__Groups" :key="aG.AgeGroupId">
							{{aG.repetition}}x{{aG.quantity}} {{measurementUnits.find(mu => mu.id==MU.MeasurementUnitId).unit}}, becsült idő: {{aG.time}}
							<template v-slot:activator="{on, attrs}">
								<v-chip
									v-on="on"
									v-bind="attrs"
									:close="$store.getters.userRole>=roles.nationalCoordinator"
									@click:close="dialogs.acceptDeleteRecommendation.show = true, dialogs.acceptDeleteRecommendation.mUId=MU.MeasurementUnitId, dialogs.acceptDeleteRecommendation.rId=aG.id"
									color="warning"
								>
									{{ageGroups.find(ag => ag.id==aG.AgeGroupId).shortname}} - {{ageGroups.find(ag => ag.id==aG.AgeGroupId).name}}
								</v-chip>
							</template>
						</v-tooltip>
					</span>
					<v-chip
						color="primary"
						v-if="$store.getters.userRole>=roles.nationalCoordinator"
						@click="dialogs.addRecommendation.show=true"
					>
						Javaslat hozzáadása
						<v-icon right>fa-plus</v-icon>
					</v-chip>

				</v-chip-group>
				<v-chip-group v-if="exercise.test||exercise.competition">
					<span v-for="MU in exercise.Exercise_MeasurementUnits" :key="MU.MeasurementUnitId">
						<v-tooltip top v-for="C in MU.Criteria" :key="" >
							<span v-if="exercise.test">
								<span v-if="MU.MeasurementUnitId>-1">
									<span v-for="i in [1,2,3,4,5,6,7]" :key="i">{{C.criteria["p"+i]==0?"":(i+" pont: "+C.criteria["p"+i]+" "+measurementUnits.find(mU => mU.id==MU.MeasurementUnitId).unit+", ")}}</span>
								</span>
								<span v-else>
									<span v-for="i in [1,2,3,4,5,6,7]" :key="i">{{C.criteria["p"+i]==0?"":(i+" pont: "+time(C.criteria["p"+i])+", ")}}</span>
								</span>
							</span>
							<span v-else>
								Versenygyakorlat{{` (${measurementUnits.find(mU => mU.id==MU.MeasurementUnitId).unit}) `}}
							</span>
							kísérletek ismétlése: {{C.attempts}}
							<template v-slot:activator="{on, attrs}">
								<v-chip
									v-on="on"
									v-bind="attrs"
									:close="$store.getters.userRole>=roles.nationalCoordinator"
									@click:close="dialogs.acceptDeleteCriteria.eMUId=MU.id, dialogs.acceptDeleteCriteria.aGId=C.AgeGroupId, dialogs.acceptDeleteCriteria.show=true"
									color="yellow"
									text-color="black"
								>
									{{ageGroups.find(ag => ag.id==C.AgeGroupId).shortname}} - {{ageGroups.find(ag => ag.id==C.AgeGroupId).name}}
								</v-chip>
							</template>
						</v-tooltip>
					</span>
					<v-chip
						color="primary"
						v-if="$store.getters.userRole>=roles.nationalCoordinator"
						@click="dialogs.addCriteria.show=true"
					>
						Kritérium hozzáadása
						<v-icon right>fa-plus</v-icon>
					</v-chip>
				</v-chip-group>
				<v-chip-group>
					<v-chip
						v-for="MU in exercise.Exercise_MeasurementUnits"
						:key="MU.MeasurementUnitId"
						:close="$store.getters.userRole>=roles.nationalCoordinator"
						@click:close="dialogs.acceptDeleteMeasurementUnit.show=true, dialogs.acceptDeleteMeasurementUnit.measurementUnitId=MU.MeasurementUnitId"
						color="accent"
					>
						{{measurementUnits.find(mu => mu.id==MU.MeasurementUnitId).unit}}
					</v-chip>
					<v-chip
						color="primary"
						v-if="$store.getters.userRole>=roles.nationalCoordinator"
						@click="dialogs.addMeasurementUnit.show=true"
					>
						Mértékegység hozzáadása
						<v-icon right>fa-plus</v-icon>
					</v-chip>
				</v-chip-group>
				<v-btn v-if="$store.getters.userRole>=roles.nationalCoordinator" color="primary" @click="dialogs.edit.show=true" rounded>Szerkesztés<v-icon>fa-edit</v-icon></v-btn>
				<v-btn v-if="$store.getters.userRole>=roles.nationalCoordinator" color="red accent-4" @click="dialogs.deleteExercise.show=true" rounded>Törlés<v-icon>fa-trash</v-icon></v-btn>
				<br/>
				<span v-html="exercise.description"/><br/>
				<!--<pre>{{exercise.description}}</pre>-->
				<!--<iframe v-for="src in YouTubeSources" :key="src" :src="src" style="width:100%" height="400"/>-->
				<v-row v-for="ytid in YouTubeVideoIds" :key="ytid"  justify="center"><youtube :video-id="ytid"/></v-row>
				<v-btn v-if="$store.getters.userRole>=roles.nationalCoordinator" color="success" @click="dialogs.addMedia.show=true" rounded>Fájl/kép feltöltése<v-icon>fa-upload</v-icon></v-btn>
				<h2 v-if="exercise.Media.filter(M => !/^image/.test(M.metadata.mimeType)).length>0">Letölthető fájlok</h2>
				<div  v-for="file in exercise.Media.filter(M => !/^image/.test(M.metadata.mimeType))" :key="file.id">
					<a :download="file.metadata.originalName" :href="`data:${file.metadata.mimeType};base64,${file.buffer}`">{{file.metadata.originalName}}</a>
					<v-btn v-if="$store.getters.userRole>=roles.nationalCoordinator" color="red accent-4" rounded @click="dialogs.acceptDeleteFile.show=true, dialogs.acceptDeleteFile.mediaId=file.id">Fájl Törlése<v-icon>fa-trash</v-icon></v-btn>
				</div>
				<v-carousel hide-delimiters v-if="exercise.Media.filter(M => /^image/.test(M.metadata.mimeType)).length>0">
					<v-carousel-item v-for="img in exercise.Media.filter(M => /^image/.test(M.metadata.mimeType))" :key="img.id" :src="`data:${img.metadata.mimeType};base64,${img.buffer}`" contain>
						<v-btn v-if="$store.getters.userRole>=roles.nationalCoordinator" color="red accent-4" rounded @click="dialogs.acceptDeleteImage.show=true, dialogs.acceptDeleteImage.imageId=img.id">Kép törlése<v-icon>fa-trash</v-icon></v-btn>
					</v-carousel-item>
				</v-carousel>
			</v-sheet>

		  <v-row justify="center">
		    <v-dialog
		      v-model="dialogs.edit.show"
		      persistent
		      max-width="600px"
		    >
		      <v-card>
		        <v-card-title>
		          <span class="headline">Szerkesztés</span>
		        </v-card-title>
		        <v-card-text>
		          <v-container>
								<v-text-field
									v-model="dialogs.edit.changedExercise.name"
									prepend-inner-icon="fa-dumbbell"
									label="Gyakorlat"
									rounded
									outlined
								/>
								<!--<v-textarea
									v-model="dialogs.edit.changedExercise.description"
									prepend-inner-icon="fa-info-circle"
									label="Leírás"
									rounded
									outlined
								/>-->
								Leírás
								<trumbowyg :config="{lang: 'hu'}" v-model="dialogs.edit.changedExercise.description"/>
								<v-textarea
									v-model="dialogs.edit.changedExercise.YouTubeLinks"
									prepend-inner-icon="fa-video"
									label="YouTube linkek (soronként 1)"
									rounded
									outlined
								/>
								<v-checkbox
									v-model="dialogs.edit.changedExercise.training"
									label="Foglalkozásgyakorlat"
								/>
								<v-checkbox
									v-model="dialogs.edit.changedExercise.competition"
									label="Versenygyakorlat"
								/>
								<v-checkbox
									v-model="dialogs.edit.changedExercise.test"
									label="Tesztgyakorlat"
								/>
		          </v-container>
		        </v-card-text>
		        <v-card-actions>
		          <v-spacer></v-spacer>
		          <v-btn
		            color="blue darken-1"
		            text
		            @click="dialogs.edit.show = false"
		          >
		            Mégsem
		          </v-btn>
		          <v-btn
		            color="blue darken-1"
		            text
		            @click="change()"
								v-if="dialogs.edit.changedExercise.name.length>0"
		          >
		            Módosítás
		          </v-btn>
		        </v-card-actions>
		      </v-card>
	    	</v-dialog>
				<v-dialog
					v-model="dialogs.addExercise_Category.show"
					persistent
					max-width="600px"
				>
					<v-card>
						<v-card-title>
							<span class="headline">Mozgásterület hozzáadása</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								<v-select
									:items="exerciseCategories.filter(c => exercise.Exercise_Categories.filter(EC => EC.id==c.id).length==0)"
									item-text="name"
									item-value="id"
									prepend-inner-icon="fa-layer-group"
									label="Mozgásterület"
									v-model="dialogs.addExercise_Category.exerciseCategoryId"
									rounded
									outlined
								/>

							</v-container>
							<div>{{exerciseCategories.find(c => c.id==dialogs.addExercise_Category.exerciseCategoryId) ? exerciseCategories.find(c => c.id==dialogs.addExercise_Category.exerciseCategoryId).description : ''}}</div>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.addExercise_Category.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								v-if="dialogs.addExercise_Category.exerciseCategoryId>-1"
								@click="addExercise_Category()"
							>
								Hozzáadás
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog
					v-model="dialogs.acceptDeleteExercise_Category.show"
					persistent
					max-width="600px"
				>
					<v-card>
						<v-card-title>
							<span class="headline">Mozgásterület törlése</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								Biztos benne, hogy törli a mozgásterületet a gyakorlattól?
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.acceptDeleteExercise_Category.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="deleteExercise_Category(dialogs.acceptDeleteExercise_Category.exerciseCategoryId)"
							>
								Törlés
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog
					v-model="dialogs.addMeasurementUnit.show"
					persistent
					max-width="600px"
				>
					<v-card>
						<v-card-title>
							<span class="headline">Mértékegység hozzáadása</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								<v-select
									:items="measurementUnits.filter(mu => exercise.Exercise_MeasurementUnits.filter(MU => MU.MeasurementUnitId==mu.id).length==0&&(exercise.test||exercise.competition?true:mu.id>-1))"
									item-text="unit"
									item-value="id"
									prepend-inner-icon="fa-layer-group"
									label="Mértékegység"
									v-model="dialogs.addMeasurementUnit.measurementUnitId"
									rounded
									outlined
								/>

							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.addMeasurementUnit.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								v-if="dialogs.addMeasurementUnit.measurementUnitId>-10"
								@click="addMeasurementUnit()"
							>
								Hozzáadás
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog
					v-model="dialogs.acceptDeleteMeasurementUnit.show"
					persistent
					max-width="600px"
				>
					<v-card>
						<v-card-title>
							<span class="headline">Mértékegység törlése</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								Biztos benne, hogy törli a mértékegységet ettől a gyakorlattól? A mértékegység törlésével az edzéstervekből is törlődik a szerkesztett gyakorlat ezzel a mértékegységgel.
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.acceptDeleteMeasurementUnit.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="deleteMeasurementUnit(dialogs.acceptDeleteMeasurementUnit.measurementUnitId)"
							>
								Törlés
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog
					v-model="dialogs.deleteExercise.show"
					persistent
					max-width="600px"
				>
					<v-card>
						<v-card-title>
							<span class="headline">Gyakorlat törlése</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								Biztos benne, hogy törli a gyakorlatot? A gyakorlat törlésével az edzéstervekből is törlődik a gyakorlat.
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.deleteExercise.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="deleteExercise()"
							>
								Törlés
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog
					v-model="dialogs.acceptDeleteImage.show"
					persistent
					max-width="600px"
				>
					<v-card>
						<v-card-title>
							<span class="headline">Kép törlése</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								Biztos benne, hogy törli a képet a gyakorlattól?
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.acceptDeleteImage.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="deleteMedia(dialogs.acceptDeleteImage.imageId)"
							>
								Törlés
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog
					v-model="dialogs.acceptDeleteFile.show"
					persistent
					max-width="600px"
				>
					<v-card>
						<v-card-title>
							<span class="headline">Fájl törlése</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								Biztos benne, hogy törli a fájlt a gyakorlattól?
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.acceptDeleteFile.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="deleteMedia(dialogs.acceptDeleteFile.mediaId)"
							>
								Törlés
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog
					v-model="dialogs.addMedia.show"
					persistent
					max-width="600px"
				>
					<v-card>
						<v-card-title>
							<span class="headline">Fájl/kép feltöltése</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								<v-file-input
									v-model="dialogs.addMedia.file"
									rounded
									label="Fájl"
									outlined
								/>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.addMedia.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								v-if="dialogs.addMedia.file"
								@click="addMedia()"
							>
								Feltöltés
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog
					v-model="dialogs.addRecommendation.show"
					persistent
					max-width="900px"
				>
					<v-card>
						<v-card-title>
							<span class="headline">Javaslat hozzáadása</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								<v-select
									rounded
									outlined
									prepend-inner-icon="fa-child"
									label="Javasolt korcsoport"
									v-model="dialogs.addRecommendation.recommendation.AgeGroupId"
									:items="ageGroups"
									item-value="id"
									item-text="shortname"
								/>
								<v-row>
									<v-col cols="12" sm="4">
										<v-numeric
											outlined
											rounded
											v-model="dialogs.addRecommendation.recommendation.recommendedRepetition"
											label="Ismétlés"
											prepend-inner-icon="fa-redo"
											locale="hu-HU"
											min="1"
											useCalculator="false"
										/>
										<!--<v-text-field
											rounded
											outlined
											prepend-inner-icon="fa-redo"
											label="Ismétlés"
											v-model="dialogs.addRecommendation.recommendation.recommendedRepetition"
										/>-->
									</v-col>
									<v-col cols="12" sm="4">
										<v-numeric
											rounded
											outlined
											prepend-inner-icon="fa-dumbbell"
											label="Mennyiség"
											min="1"
											v-model="dialogs.addRecommendation.recommendation.recommendedQuantity"
											precision="1"
											useCalculator="false"
											locale="hu-HU"
										/>
										<!--<v-text-field
											rounded
											outlined
											prepend-inner-icon="fa-dumbbell"
											label="Mennyiség"
											v-model="dialogs.addRecommendation.recommendation.recommendedQuantity"
										/>-->
									</v-col>
									<v-col cols="12" sm="4">
										<v-select
											rounded
											outlined
											label="Mértékegység"
											:items="selectExercise_MeasurementUnits"
											item-value="id"
											item-text="mU"
											v-model="dialogs.addRecommendation.recommendation.ExerciseMeasurementUnitId"
										/>
									</v-col>
								</v-row>
								<v-text-field
									rounded
									outlined
									prepend-inner-icon="fa-hourglass-start"
									label="Becsült idő"
									v-model="dialogs.addRecommendation.recommendation.estimatedTime"
								/>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.addRecommendation.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								v-if="dialogs.addRecommendation.recommendation.AgeGroupId!=-1&&dialogs.addRecommendation.recommendation.recommendedRepetition>0&&dialogs.addRecommendation.recommendation.recommendedQuantity>0&&dialogs.addRecommendation.recommendation.ExerciseMeasurementUnitId!=-1"
								@click="addRecommendation()"
							>
								Hozzáadás
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog
					v-model="dialogs.acceptDeleteRecommendation.show"
					persistent
					max-width="600px"
				>
					<v-card>
						<v-card-title>
							<span class="headline">Javaslat törlése</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								Biztosan törli a javaslatot?
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.acceptDeleteRecommendation.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="deleteRecommendation()"
							>
								Törlés
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<v-dialog
					v-model="dialogs.addCriteria.show"
					persistent
					max-width="900px"
				>
					<v-card>
						<v-card-title>
							<span class="headline">Kritérium hozzáadása</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								<v-select
									rounded
									outlined
									prepend-inner-icon="fa-child"
									label="Korcsoport"
									v-model="dialogs.addCriteria.criteria.AgeGroupId"
									:items="ageGroups"
									item-value="id"
									item-text="shortname"
								/>
								<v-select
									rounded
									outlined
									label="Mértékegység"
									:items="selectExercise_MeasurementUnits"
									item-value="id"
									item-text="mU"
									v-model="dialogs.addCriteria.criteria.EMUId"
									@change="() => {
										['p1','p2','p3','p4','p5','p6','p7'].forEach(p => {
											dialogs.addCriteria.criteria.criteria[p]=exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUId).MeasurementUnitId==-1?'00:00:00.000':'0'
											})
										}"
								/>
								<v-text-field
									rounded
									outlined
									prepend-inner-icon="fa-star"
									label="Kísérletek ismétlése"
									v-model="dialogs.addCriteria.criteria.attempts"
									:rules="[dialogs.addCriteria.rules.required,dialogs.addCriteria.rules.isNumber]"
								/>
								<v-switch
									v-model="dialogs.addCriteria.criteria.ascendingTendent"
									:label="dialogs.addCriteria.criteria.ascendingTendent?'Növekvő tendencia (a több ponthoz magasabb számértéket kell teljesíteni, pl. ugrások)' : 'Csökkenő tendencia (a több ponthoz alacsonyabb számértéket kell teljesíteni, pl. futás ideje)'"
								/>
								<span v-if="exercise.test">
									<v-row>
										<v-col cols="12" sm="4">
											<v-text-field
												rounded
												outlined
												prepend-inner-icon="fa-star"
												label="1 pont"
												:placeholder="exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUID)?(exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUId).MeasurementUnitId==-1?'hh:mm:ss.mss':''):''"
												v-model="dialogs.addCriteria.criteria.criteria.p1"
												:rules="[dialogs.addCriteria.rules.required,
													exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUId)?(exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUId).MeasurementUnitId==-1?dialogs.addCriteria.rules.isTime:dialogs.addCriteria.rules.isNumber):dialogs.addCriteria.rules.isNumber
												]"
											/>
										</v-col>
										<v-col cols="12" sm="4">
											<v-text-field
												rounded
												outlined
												prepend-inner-icon="fa-star"
												label="2 pont"
												:placeholder="exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUID)?(exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUId).MeasurementUnitId==-1?'hh:mm:ss.mss':''):''"
												v-model="dialogs.addCriteria.criteria.criteria.p2"
												:rules="[dialogs.addCriteria.rules.required,
													exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUId)?(exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUId).MeasurementUnitId==-1?dialogs.addCriteria.rules.isTime:dialogs.addCriteria.rules.isNumber):dialogs.addCriteria.rules.isNumber
												]"
											/>
										</v-col>
										<v-col cols="12" sm="4">
											<v-text-field
												rounded
												outlined
												prepend-inner-icon="fa-star"
												label="3 pont"
												:placeholder="exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUID)?(exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUId).MeasurementUnitId==-1?'hh:mm:ss.mss':''):''"
												v-model="dialogs.addCriteria.criteria.criteria.p3"
												:rules="[dialogs.addCriteria.rules.required,
													exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUId)?(exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUId).MeasurementUnitId==-1?dialogs.addCriteria.rules.isTime:dialogs.addCriteria.rules.isNumber):dialogs.addCriteria.rules.isNumber
												]"
											/>
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="12" sm="3">
											<v-text-field
												rounded
												outlined
												prepend-inner-icon="fa-star"
												label="4 pont"
												:placeholder="exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUID)?(exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUId).MeasurementUnitId==-1?'hh:mm:ss.mss':''):''"
												v-model="dialogs.addCriteria.criteria.criteria.p4"
												:rules="[dialogs.addCriteria.rules.required,
													exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUId)?(exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUId).MeasurementUnitId==-1?dialogs.addCriteria.rules.isTime:dialogs.addCriteria.rules.isNumber):dialogs.addCriteria.rules.isNumber
												]"
											/>
										</v-col>
										<v-col cols="12" sm="3">
											<v-text-field
												rounded
												outlined
												prepend-inner-icon="fa-star"
												label="5 pont"
												:placeholder="exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUID)?(exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUId).MeasurementUnitId==-1?'hh:mm:ss.mss':''):''"
												v-model="dialogs.addCriteria.criteria.criteria.p5"
												:rules="[dialogs.addCriteria.rules.required,
													exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUId)?(exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUId).MeasurementUnitId==-1?dialogs.addCriteria.rules.isTime:dialogs.addCriteria.rules.isNumber):dialogs.addCriteria.rules.isNumber
												]"
											/>
										</v-col>
										<v-col cols="12" sm="3">
											<v-text-field
												rounded
												outlined
												prepend-inner-icon="fa-star"
												label="6 pont"
												:placeholder="exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUID)?(exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUId).MeasurementUnitId==-1?'hh:mm:ss.mss':''):''"
												v-model="dialogs.addCriteria.criteria.criteria.p6"
												:rules="[dialogs.addCriteria.rules.required,
													exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUId)?(exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUId).MeasurementUnitId==-1?dialogs.addCriteria.rules.isTime:dialogs.addCriteria.rules.isNumber):dialogs.addCriteria.rules.isNumber
												]"
											/>
										</v-col>
										<v-col cols="12" sm="3">
											<v-text-field
												rounded
												outlined
												prepend-inner-icon="fa-star"
												label="7 pont"
												:placeholder="exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUID)?(exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUId).MeasurementUnitId==-1?'hh:mm:ss.mss':''):''"
												v-model="dialogs.addCriteria.criteria.criteria.p7"
												:rules="[dialogs.addCriteria.rules.required,
													exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUId)?(exercise.Exercise_MeasurementUnits.find(MU => MU.id==dialogs.addCriteria.criteria.EMUId).MeasurementUnitId==-1?dialogs.addCriteria.rules.isTime:dialogs.addCriteria.rules.isNumber):dialogs.addCriteria.rules.isNumber
												]"
											/>
										</v-col>
									</v-row>
								</span>

							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.addCriteria.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="addCriteria()"
							>
								Hozzáadás
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog
					v-model="dialogs.acceptDeleteCriteria.show"
					persistent
					max-width="600px"
				>
					<v-card>
						<v-card-title>
							<span class="headline">Kritérium törlése</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								Biztosan törli a kritériumot?
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.acceptDeleteCriteria.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="deleteCriteria()"
							>
								Törlés
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

			</v-row>
	</template>
	</div>
</template>

<script>
	import router from '../router';
	export default {
    props: ['id'],
		data: function(){
			return {
        exercise: {},
        measurementUnits: [],
        exerciseCategories: [],
				ageGroups: [],
        dialogs: {
					edit: {
						show: false,
						changedExercise: {name: '', description: '', YouTubeLinks: '', competition: false, test: false, training: false}
					},
					deleteExercise: {
						show: false
					},


					addExercise_Category: {
						show: false,
						exerciseCategoryId: -1
					},
					acceptDeleteExercise_Category: {
						show: false,
						exerciseCategoryId: -1
					},


					addMeasurementUnit: {
						show: false,
						measurementUnitId: -10,
					},
					acceptDeleteMeasurementUnit: {
						show: false,
						measurementUnitId: -10
					},


					addMedia: {
						show: false,
						file: null
					},
					acceptDeleteImage: {
						show: false,
						imageId: -1
					},
					acceptDeleteFile: {
						show: false,
						mediaId: -1
					},


					addRecommendation: {
						show: false,
						recommendation: {
							AgeGroupId: -1,
							recommendedQuantity: 1,
							recommendedRepetition: 1,
							estimatedTime: '',
							ExerciseMeasurementUnitId: -1
						}
					},
					acceptDeleteRecommendation: {
						show: false,
						mUId: -1,
						rId: -1
					},


					addCriteria: {
						show: false,
						criteria: {
							EMUId: -1,
							AgeGroupId: -1,
							ascendingTendent: false,
							attempts: 1,
							criteria: {
								p1: "0",
								p2: "0",
								p3: "0",
								p4: "0",
								p5: "0",
								p6: "0",
								p7: "0",
							}
						},
						rules: {
							required: value => !!value|| 'Töltse ki ezt a mezőt!',
							isNumber: value => {value = value.replace(",","."); return (!isNaN(value)&&value>=0) || "0-t vagy annál nagyobb számot adjon meg!"},
							isTime: value => {
								const timeCriteriaPattern = /^(?<time>(?<hours>\d{2}):(?<minutes>[0-5]\d):(?<seconds>[0-5]\d)\.(?<milliseconds>\d{3}))$/;
								return timeCriteriaPattern.test(value) || "Időt adjon meg hh:mm:ss.mss formában!"
							}
						}
					},
					acceptDeleteCriteria: {
						show: false,
						eMUId: -1,
						aGId: -1
					}

        },
      }
		},
		computed: {
			selectExercise_MeasurementUnits() {
				return this.exercise.Exercise_MeasurementUnits.map(EMU => {
					return {
						id: EMU.id,
						mU: this.measurementUnits.find(MU => MU.id==EMU.MeasurementUnitId).unit
					}
				})
			},
			YouTubeSources() {
				return this.exercise.YouTubeLinks !==null ? this.exercise.YouTubeLinks.split('\n').map(YTL =>  "https://www.youtube.com/embed/"+YTL.substring(16)) : []
			},
			YouTubeVideoIds() {
				return this.exercise.YouTubeLinks !==null ? this.exercise.YouTubeLinks.split('\n').map(YTL =>YTL.substring(16)) : []
			}
		},
		watch: {

		},
		methods: {
			change: function() {
				this.axios({url: "exercise/"+this.$route.params.id, method: "PUT", data: this.dialogs.edit.changedExercise}).then((response) => {
					if(response.data.success) {
						this.exercise.name=this.dialogs.edit.changedExercise.name;
						this.exercise.description=this.dialogs.edit.changedExercise.description;
						this.exercise.competition=this.dialogs.edit.changedExercise.competition;
						this.exercise.test=this.dialogs.edit.changedExercise.test;
						this.exercise.YouTubeLinks=this.dialogs.edit.changedExercise.YouTubeLinks;
						this.exercise.training=this.dialogs.edit.changedExercise.training;
						this.dialogs.edit.show=false;
						this.$store.commit('setSnack','A módosítás sikeresen megtörtént.')
					}
				})
			},
			deleteExercise: function() {
				this.axios({url: "exercise/"+this.$route.params.id, method: "DELETE"}).then((response) => {
					if(response.data.success){
						this.$store.commit('setSnack','A törlés sikeresen megtörtént.')
						this.dialogs.deleteExercise.show=false;
						this.$router.push('/exercises')
					}
				})
			},

			addExercise_Category: function() {
				this.axios({url: 'exercise/'+this.$route.params.id+"/category", method: "POST", data: {exerciseCategoryId: this.dialogs.addExercise_Category.exerciseCategoryId}}).then((response) => {
					if(response.data.success) {
						this.dialogs.addExercise_Category.show=false;
						this.exercise.Exercise_Categories.push({id: this.dialogs.addExercise_Category.exerciseCategoryId})
						this.dialogs.addExercise_Category.exerciseCategoryId=-1;
						this.$store.commit('setSnack', 'A hozzáadás sikeresen megtörtént.')
					}
				})
			},
			deleteExercise_Category: function(exerciseCategoryId) {
				this.axios({url: "exercise/"+this.$route.params.id+"/category/"+exerciseCategoryId, method: "DELETE"}).then((response) => {
					if(response.data.success) {
						this.exercise.Exercise_Categories.splice(this.exercise.Exercise_Categories.findIndex(EC => EC.id==exerciseCategoryId),1)
						this.dialogs.acceptDeleteExercise_Category.show=false
						this.$store.commit('setSnack','A törlés sikeresen megtörtént.')
					}
				})
			},

			addMeasurementUnit: function() {
				this.axios({url: 'exercise/'+this.$route.params.id+"/measurementunit", method: "POST", data: {measurementUnitId: this.dialogs.addMeasurementUnit.measurementUnitId}}).then((response) => {
					if(response.data.success) {
						this.dialogs.addMeasurementUnit.show=false;
						this.exercise.Exercise_MeasurementUnits.push({MeasurementUnitId: this.dialogs.addMeasurementUnit.measurementUnitId, ExerciseMU_Age__Groups: [], id: response.data.data, Criteria: []})
						this.dialogs.addMeasurementUnit.measurementUnitId=-1;
						this.$store.commit('setSnack', 'A hozzáadás sikeresen megtörtént.')
					}
				})
			},
			deleteMeasurementUnit: function(measurementUnitId) {
				this.axios({url: "exercise/"+this.$route.params.id+"/measurementunit/"+measurementUnitId, method: "DELETE"}).then((response) => {
					if(response.data.success) {
						this.exercise.Exercise_MeasurementUnits.splice(this.exercise.Exercise_MeasurementUnits.findIndex(MU => MU.MeasurementUnitId==measurementUnitId),1)
						this.dialogs.acceptDeleteMeasurementUnit.show=false
						this.$store.commit('setSnack','A törlés sikeresen megtörtént.')
					}
				})
			},

			addMedia: function() {
				var formData = new FormData();
				formData.append('file',this.dialogs.addMedia.file);
				this.axios.post("exercise/"+this.$route.params.id+"/media", formData, {headers: { 'Content-Type': 'multipart/form-data'}}).then((response) => {
					if(response.data.success) {
						this.dialogs.addMedia.show=false;
						this.dialogs.addMedia.file=null
						this.exercise.Media.push(response.data.data.media)
						this.$store.commit('setSnack', 'A fájl feltöltése sikeresen megtörtént. Ha nem látja, frissítse az oldalt!')
					}
				})
			},
			deleteMedia: function(mediaId) {
				this.axios({url: "exercise/media/"+mediaId, method: "DELETE"}).then((response) => {
					if(response.data.success) {
						this.exercise.Media.splice(this.exercise.Media.findIndex(M => M.id==mediaId),1)
						this.dialogs.acceptDeleteImage.show=false;
						this.dialogs.acceptDeleteFile.show=false
						this.$store.commit('setSnack', 'A törlés sikeresen megtörtént.')
					}
				})
			},

			addRecommendation: function() {
				this.axios({url: 'exercise/mu-age--group', method: "POST", data: this.dialogs.addRecommendation.recommendation}).then((response) => {
					if(response.data.success) {
						this.exercise.Exercise_MeasurementUnits.find(EMU => EMU.id==this.dialogs.addRecommendation.recommendation.ExerciseMeasurementUnitId).ExerciseMU_Age__Groups.push({
							AgeGroupId: this.dialogs.addRecommendation.recommendation.AgeGroupId,
							id: response.data.data,
							quantity: this.dialogs.addRecommendation.recommendation.recommendedQuantity,
							repetition: this.dialogs.addRecommendation.recommendation.recommendedRepetition,
							time: this.dialogs.addRecommendation.recommendation.estimatedTime
						})
						this.dialogs.addRecommendation.show=false;
						this.$store.commit('setSnack', 'A hozzáadás sikeresen megtörtént.')
					}
				})
			},
			deleteRecommendation:  function() {
				this.axios({url: 'exercise/mu-age--group/'+this.dialogs.acceptDeleteRecommendation.rId, method: "DELETE"}).then((response) => {
					if(response.data.success) {
						var eMU = this.exercise.Exercise_MeasurementUnits.find(EMU => EMU.MeasurementUnitId==this.dialogs.acceptDeleteRecommendation.mUId)
						eMU.ExerciseMU_Age__Groups.splice(eMU.ExerciseMU_Age__Groups.findIndex(EMUAG => EMUAG.id==this.dialogs.acceptDeleteRecommendation.rId),1)
						this.dialogs.acceptDeleteRecommendation.show=false;
						this.$store.commit('setSnack', 'A törlés sikeresen megtörtént.')
					}
				})
			},

			addCriteria: function() {
				const criteria = {...this.dialogs.addCriteria.criteria.criteria}
				var criteriaOk=true
				if(this.exercise.Exercise_MeasurementUnits.find(EMU => EMU.id==this.dialogs.addCriteria.criteria.EMUId).MeasurementUnitId==-1) {
					const timeCriteriaPattern = /^(?<time>(?<hours>\d{2}):(?<minutes>[0-5]\d):(?<seconds>[0-5]\d)\.(?<milliseconds>\d{3}))$/;
					for(const C in criteria) {
						if(timeCriteriaPattern.test(criteria[C])){
							const splitted = criteria[C].split(":");
							const splittedSmS = splitted[2].split(".")
							criteria[C]=(splitted[0]*3600000+splitted[1]*60000+splittedSmS[0]*1000+splittedSmS[1]*1)
						} else criteriaOk = false
					}
				}
				else
					for(const C in criteria) {
						criteria[C] = criteria[C].includes(",") ? criteria[C].replace(",",".") : criteria[C]
						if(isNaN(criteria[C])) criteriaOk = false
					}
				if(criteriaOk){
					var req = {...this.dialogs.addCriteria.criteria}
					req.criteria={...criteria}
					this.axios({url: 'exercise/criteria', method: "POST", data: req}).then((response) => {
						if(response.data.success) {
							var eMU = this.exercise.Exercise_MeasurementUnits.find(EMU => EMU.id==this.dialogs.addCriteria.criteria.EMUId)
							eMU.Criteria.push(response.data.data);
							this.$store.commit('setSnack', 'A hozzáadás sikeresen megtörtént.')
							this.dialogs.addCriteria.show=false
						}
					})
				}
				else {
					this.$store.commit('setSnack', 'A kritérium formája nem teljesíti az elvárást')
				}
			},
			deleteCriteria: function() {
				this.axios({url: 'exercise/criteria/'+this.dialogs.acceptDeleteCriteria.eMUId+"/"+this.dialogs.acceptDeleteCriteria.aGId, method: "DELETE"}).then(response => {
					if(response.data.success) {
						var eMU = this.exercise.Exercise_MeasurementUnits.find(EMU => EMU.id==this.dialogs.acceptDeleteCriteria.eMUId)
						eMU.Criteria.splice(eMU.Criteria.findIndex(C => C.AgeGroupId==this.dialogs.acceptDeleteCriteria.aGID),1)
						this.$store.commit('setSnack', 'A törlés sikeresen megtörtént.')
						this.dialogs.acceptDeleteCriteria.show=false
					}
				})
			}
		},
		mounted(){
			this.axios({url: "exercise/"+this.$route.params.id, method: "GET"}).then((response) => {
        if(response.data.success) {
          this.exercise = response.data.data.exercise
					this.dialogs.edit.changedExercise = {name: this.exercise.name, description: this.exercise.description, competition: this.exercise.competition, test: this.exercise.test, YouTubeLinks: this.exercise.YouTubeLinks, training: this.exercise.training}
          this.measurementUnits = response.data.data.measurementUnits
					this.exerciseCategories = response.data.data.exerciseCategories
					this.ageGroups = response.data.data.ageGroups;
        }
			})
		}
	}
</script>
